/* eslint-disable max-len */

export const GetVehicleYearsQuery = `
  query GetVehicleYears {
    getVehicleYears(input: {}) {
      year
    }
  }
`;

export const GetVehicleMakesQuery = `
  query GetVehicleBrands($year: Int $sfacId: Int) {
    getVehicleBrands(input: {
      year: $year
      sfacId: $sfacId
    }) {
      brand
    }
  }
`;

export const GetAllVehicleMakesQuery = `
  query GetAllVehicleBrands {
    vehicleBrands {
      name
    }
  }
`;

export const GetVehicleModelFromYearMakesQuery = `
  query GetVehicleModelFromYearMakes($year: Int!, $make: String!) {
    getVehicleModelFromYearMakes(input: {
      year: $year
      make: $make
    }) {
      model
      years
    }
  }
`;

export const GetServicePriceQuery = `
  query GetServicePrice($serviceId: Int!) {
    getSFACEditableAdminServicePrice(input: {
      serviceId: $serviceId
    }) {
      id
      name
      displayName
      params
      priceFunction
      laborRateType
      isPriceFree
    }
  }
`;

export const GetServicePriceAfterUpdateQuery = `
  query GetServicePriceAfterUpdate($sfacId: Int, $serviceId: Int!, $serviceProfileId: Int, $ownerId: Int!, $serviceName: String!, $laborRateType: String, $params: JSON) {
    getSFACServicePrice(input: {
      sfacId: $sfacId,
      serviceId: $serviceId
      serviceName : $serviceName
      serviceProfileId: $serviceProfileId
      ownerId: $ownerId
      laborRateType: $laborRateType
      params: $params
    }) {
      serviceId
      serviceName
      priceRange {
           max
           min
         }
      priceCents
    }
  }
`;

export const GetServicePricesQuery = `
  query GetServicePrices($sfacId: Int!) {
    getServicePriceByLocations(input: {
      sfacId: $sfacId
    }) {
      id
    }
  }
`;

export const UpdateServicePriceQuery = `
  mutation UpdateServicePrice(
      $serviceId: Int!
      $params: JSON,
      $priceFunction: String,
      $laborRateType: String,
      $displayName: String,
      $isPriceFree: Boolean,
      $durationMinutesLow: Int,
      $durationMinutesHigh: Int,
      $durationUnit: String
    ) {
      updateSFACEditableAdminServicePrice(input: {
        serviceId: $serviceId,
        params: $params,
        priceFunction: $priceFunction,
        laborRateType: $laborRateType,
        displayName: $displayName,
        isPriceFree: $isPriceFree,
        durationMinutesLow: $durationMinutesLow,
        durationMinutesHigh: $durationMinutesHigh,
        durationUnit: $durationUnit,
      }) {
        id
      }
    }
`;

export const GetSFACLocationPeriodQuery = `
  query GetSFACLocationPeriodQuery(
    $sfacId: Int!
  ) {
    getSFACScheduleByLocations(input: {
      sfacId: $sfacId
    }) {
      id
      date
      closed
      startTime
      startBreak
      endBreak
      endTime
    }
  }
`;

export const GetSFACHolidaysAndClosuresQuery = `
  query GetSFACHolidaysAndClosuresQuery(
    $sfacId: Int
  ) {
    getSFACHolidaysAndClosuresByLocations(input: {
      sfacId: $sfacId
    }) {
      id
      date
      closed
      sfacId
      startTime
      endTime
    }
  }
`;

export const GetRegionHolidaysAndClosuresQuery = `
  query GetRegionHolidaysAndClosuresQuery(
    $regionId: Int
  ) {
    getRegionHolidaysAndClosures(input: {
      regionId: $regionId
    }) {
      id
      date
      closed
      regionId
      startTime
      endTime
    }
  }
`;

export const UpdateRegionHolidaysAndClosuresMutation = `
  mutation UpdateRegionHolidaysAndClosures(
    $id: Int!
    $regionId: Int
    $closed: Boolean
    $date: String
    $startTime: String
    $endTime: String
  ) {
    updateRegionHolidaysAndClosures(input: {
      id: $id
      regionId: $regionId
      closed: $closed
      date: $date
      startTime: $startTime
      endTime: $endTime
    }) {
      id
    }
  }
`;

export const DeleteRegionHolidaysAndClosuresMutation = `
  mutation DeleteRegionHolidaysAndClosures(
    $id: Int
  ) {
    deleteRegionHolidaysAndClosures(input: {
      id: $id
    }) {
      id
    }
  }
`;

export const CreateRegionHolidaysAndClosuresMutation = `
  mutation createRegionHolidaysAndClosures(
    $regionId: Int
    $closed: Boolean
    $date: String
    $startTime: String
    $endTime: String
  ) {
    createRegionHolidaysAndClosures(input: {
      regionId: $regionId
      date: $date
      startTime: $startTime
      endTime: $endTime
      closed: $closed
    }) {
      id
    }
  }
`;

export const CreateLocationHolidaysAndClosuresQuery = `
  mutation createLocationHolidaysAndClosures(
    $sfacId: Int
    $closed: Boolean
    $date: String
    $startTime: String
    $endTime: String
  ) {
    createLocationHolidaysAndClosures(input: {
      sfacId: $sfacId
      date: $date
      startTime: $startTime
      endTime: $endTime
      closed: $closed
    }) {
      id
    }
  }
`;

export const GetLocationPeriodByIdQuery = `
  query GetLocationPeriodByIdQuery(
    $id: Int!
  ) {
    locationPeriod(input: {
      id: $id
    }) {
      id
      date
      closed
      startTime
      startBreak
      endBreak
      endTime
      sfacId
    }
  }
`;

export const UpdateSFACLocationPeriodQuery = `
  mutation UpdateSFACLocationPeriod(
    $id: Int!
    $sfacId: Int
    $date: String
    $closed: Boolean
    $startTime: String
    $endTime: String
    $startBreak: String
    $endBreak: String
  ) {
    updateLocationPeriod(input: {
      id: $id
      sfacId: $sfacId
      date: $date
      startTime: $startTime
      endTime: $endTime
      startBreak: $startBreak
      endBreak: $endBreak
      closed: $closed
    }) {
      id
    }
  }
`;

export const GetSFACLocationPeriodTemplateQuery = `
  query GetSFACLocationPeriodTemplateQuery(
    $sfacId: Int!
  ) {
    getLocationPeriodTemplatesByLocations(input: {
      sfacId: $sfacId
    }) {
      id
      day
      closed
      startTimeLocalHour
      startTimeMinute
      startBreakLocalHour
      startBreakMinute
      endBreakLocalHour
      endBreakMinute
      endTimeLocalHour
      endTimeMinute
    }
  }
`;

export const GetLocationMaxAppointmentsWaitersPerDayQuery = `
  query GetLocationMaxAppointmentsWaitersPerDayQuery(
    $locationId: Int!
  ) {
    locationMaxAppointmentsWaitersPerDays(input: {
      locationId: $locationId
    }) {
      id
      day
      maxAppointmentsCount
      maxWaitersCount
      closed
    }
  }
`;

export const UpdateLocationMaxAppointmentsWaitersPerDayQuery = `
  mutation UpdateLocationMaxAppointmentsWaitersPerDay(
    $id: Int!
    $day: Int
    $maxAppointmentsCount: Int
    $maxWaitersCount: Int
  ) {
    updateLocationMaxAppointmentsWaitersPerDay(input: {
      id: $id
      day: $day
      maxAppointmentsCount: $maxAppointmentsCount
      maxWaitersCount: $maxWaitersCount
    }) {
      id
    }
  }
`;

export const UpdateSFACLocationTemplatePeriodQuery = `
  mutation UpdateLocationPeriodTemplate(
    $id: Int!
    $sfacId: Int
    $closed: Boolean
    $startTimeLocalHour: Int
    $startTimeMinute: Int
    $startBreakLocalHour: Int
    $startBreakMinute: Int
    $endBreakLocalHour: Int
    $endBreakMinute: Int
    $endTimeLocalHour: Int
    $endTimeMinute: Int
  ) {
    updateLocationPeriodTemplate(input: {
      id: $id
      sfacId: $sfacId
      closed: $closed
      startTimeLocalHour: $startTimeLocalHour
      startTimeMinute: $startTimeMinute
      startBreakLocalHour: $startBreakLocalHour
      startBreakMinute: $startBreakMinute
      endBreakLocalHour: $endBreakLocalHour
      endBreakMinute: $endBreakMinute
      endTimeLocalHour: $endTimeLocalHour
      endTimeMinute: $endTimeMinute
    }) {
      id
    }
  }
`;

export const UpdateSFACLocationHolidaysAndClosuresQuery = `
  mutation UpdateSFACLocationHolidaysAndClosures(
    $id: Int!
    $sfacId: Int
    $closed: Boolean
    $date: String
    $startTime: String
    $endTime: String
  ) {
    updateLocationHolidaysAndClosures(input: {
      id: $id
      sfacId: $sfacId
      closed: $closed
      date: $date
      startTime: $startTime
      endTime: $endTime
    }) {
      id
    }
  }
`;

export const DeleteSFACLocationHolidaysAndClosuresQuery = `
  mutation DeleteSFACLocationHolidaysAndClosures(
    $id: Int
  ) {
    deleteLocationHolidaysAndClosures(input: {
      id: $id
    }) {
      id
    }
  }
`;

export const UpdateLocationPeriodsFromTemplateQuery = `
  mutation  UpdateSFACLocationPeriodsFromTemplate ($sfacId: Int, $update: Boolean) {
    updateSFACLocationPeriodsFromTemplates(input: {
      sfacId: $sfacId
      update: $update
    }) {
      id
    }
  }
`;

export const UpdateVehicleBrandsForLocation = `
  mutation UpdateVehicleBrandsForLocation($sfacId: Int, $brands: [String]) {
    updateVehicleBrandsForLocations(input: {
      sfacId: $sfacId,
      brands: $brands
    }) {
      id
    }
  }
`;

export const GetLocation = `
  query Location($id: Int, $organizationId: Int) {
    location(input: {
      id: $id
    }) {
      id
      name
      displayName
      displayLocation
      phone
      address {
        id
        street
        city
        state
        zipcode
      }
      appUrlSlug
      tirePurchaseUrl
      shopHomeUrl
      shopLogoUrl
      staffBioUrl
      pricingUrl
      phoneText
      confirmationText
      email
      shopAppointmentsUrl
      timezone
      latitude
      longitude
      yelpReviewsBadge
      yelpReviewsLink
      yelpReviewsNumber
      googleReviewsBadge
      googleReviewsLink
      googleReviewsNumber
      active
      openingDate
      overrideCorporateLogo
      locationImages {
        id
        name
        url
        type
      }
      services {
        id
        name
        displayName
      }
      locationDailyServiceAdvisorCalls {
        id
        maxNumberOfCalls
        timePeriod
        timeUnit
      }
      locationManagers {
        id
        firstName
        lastName
        email
      }
      equipments {
        id
        displayName
        amount
      }
      laborRates {
        id
        name
        displayName
        rate
        isRemovable
      }
      locationLaborRate {
        id
        shopId
        standardLaborRate
        diagnosticLaborRate
        premiumLaborRate
        discountedLaborRate
      }  
      region {
       id
       name
      }
      videoFeeds {
       id
       videoUrl
      }
    }
    corporate(input: {
      organizationId: $organizationId
    }) {
      id
      logoUrl
      phone
      gaTag
      conversionTag
      conversionTagSendTo
      shopSpecialsUrl
    }
  }
`;

export const GetLocationsNames = `
  query GetLocationsNames($organizationId: Int, $regionId: Int, $districtId: Int, $id: Int, $isShowCheckResult: Boolean) {
    locations(input: { organizationId: $organizationId, regionId: $regionId, districtId: $districtId, id: $id, isShowCheckResult: $isShowCheckResult }) {
      id
      name
      active
      displayLocation
      locationIndex
      regionId
      districtId
      locationManager
      advisorSchedule
      supervisingTechnician
      noCapabilitiesSet
      noPicture
      noBio
    }
  }
`;

export const GetSFACCustomersQuery = `
  query Customer($sfacId: Int) {
    customers(input: { sfacId: $sfacId }) {
      id
      firstName
      lastName
    }
  }
`;

export const GetSFACBookingsQuery = `
  query Booking($sfacId: Int, $regionId: Int, $districtId: Int, $organizationId: Int, $withOffline: Boolean, $invoiced: Boolean, $showArchived: Boolean, $customerSearch: String, $createdBySearch: String, $sort: String, $limit: Int, $offset: Int, $from: String, $to: String, $scheduledFrom: String, $scheduledTo: String) {
    bookings(input: { sfacId: $sfacId, regionId: $regionId, districtId: $districtId, organizationId: $organizationId, withOffline: $withOffline, invoiced: $invoiced, showArchived: $showArchived, customerSearch: $customerSearch, createdBySearch: $createdBySearch, sort: $sort, limit: $limit, offset: $offset, from: $from, to: $to, scheduledFrom: $scheduledFrom, scheduledTo: $scheduledTo }) {
      bookings {
        id
        uuid
        date
        created_at
        trackerUrl
        firstName
        lastName
        online
        phone
        email
        year
        make
        model
        plateState
        sfacId
        sourceId
        gclid
        displayLocation
        locationDisplayName
        locationPhone
        regionName
        appUrlSlug
        organizationName
        timezone
        plateNumber
        dropoff
        scheduledTime
        customerServiceNote
        deferredServices
        createdBy
        services {
            id,
            displayName,
        }
        customer {
          id,
          firstName,
          email,
          customerPhones {
            id,
            phone,
            primary
          }
          customerEmails {
            id,
            email,
            primary
          }
          customerInfos {
            id,
            locationId,
            invoicesTotal
          }
        }
        appointmentSource
        totalInvoices
      }
      count
    }
  }
`;

export const GetSFACBookingsChartQuery = `
  query Booking($sfacId: Int, $regionId: Int, $organizationId: Int, $limit: Int, $offset: Int, $from: String, $to: String, $withOffline: Boolean, $showArchived: Boolean) {
    bookings(input: { sfacId: $sfacId, regionId: $regionId, organizationId: $organizationId, limit: $limit, offset: $offset, from: $from, to: $to, withOffline: $withOffline,  showArchived: $showArchived }) {
      bookings {
        created_at,
        scheduledTime,
        dropoff,
        sfacId,
        regionId,
        internalId,
        name,
        online,
        organizationId,
        organizationDisplayName,
        regionName
        regionDisplayName,
        locationIndex,
        displayLocation,
        invoiceCount,
        appointmentSource,
      }
      count
    }
  }
`;

export const GetBookingReportQuery = `
query GetBookingReport($regionId: Int, $organizationId: Int, $limit: Int, $offset: Int, $from: String, $to: String) {
    getBookingReports(input: {regionId: $regionId, organizationId: $organizationId, limit: $limit, offset: $offset, from: $from, to: $to}) {
      nationalBookingsData {
        created_at,
        scheduledTime,
        dropoff,
        sfacId,
        regionId,
        internalId,
        name,
        online,
        organizationId,
        organizationDisplayName,
        regionName
        regionDisplayName,
        locationIndex,
        displayLocation,
        timezone,
      }
      nationalOnlineBookingsData {
        created_at,
        scheduledTime,
        dropoff,
        sfacId,
        regionId,
        internalId,
        name,
        online,
        organizationId,
        organizationDisplayName,
        regionName
        regionDisplayName,
        locationIndex,
        displayLocation,
        timezone,
      }
      organizationBookingsData {
        created_at,
        scheduledTime,
        dropoff,
        sfacId,
        regionId,
        internalId,
        name,
        online,
        organizationId,
        organizationDisplayName,
        regionName
        regionDisplayName,
        locationIndex,
        displayLocation,
        timezone,
      }
      
      organizationOnlineBookingsData {
        created_at,
        scheduledTime,
        dropoff,
        sfacId,
        regionId,
        internalId,
        name,
        online,
        organizationId,
        organizationDisplayName,
        regionName
        regionDisplayName,
        locationIndex,
        displayLocation,
        timezone,
      }
      regionBookingsData {
        created_at,
        scheduledTime,
        dropoff,
        sfacId,
        regionId,
        internalId,
        name,
        online,
        organizationId,
        organizationDisplayName,
        regionName
        regionDisplayName,
        locationIndex,
        displayLocation,
        timezone,
      }
      regionOnlineBookingsData {
        created_at,
        scheduledTime,
        dropoff,
        sfacId,
        regionId,
        internalId,
        name,
        online,
        organizationId,
        organizationDisplayName,
        regionName
        regionDisplayName,
        locationIndex,
        displayLocation,
        timezone,
      }
    }
  }
`;

export const GetLocationIntegrations = `
  query GetLocationIntegrations($id: Int) {
    location(input: {
        id: $id
      }) {
        id
        zendeskGroupId
        zendeskEnabled
        zendeskStatus
        zendeskLastCommunication
      }
    locationSecret(input: { sfacId: $id}) {
      id
      sfacId
      protractorAuthenticationKey
      protractorApiKey
      protractorConnectionId
      atmUrl
      atmUsername
      atmPassword
      tireGuruAuthKey
      tireGuruAccountKey
      tekMetricClientId
      tekMetricClientSecret
      tekMetricShopId
      protractorEnabled
      atmEnabled
      tireGuruEnabled
      tekMetricEnabled
      protractorStatus
      tireGuruStatus
      atmStatus
      tekMetricStatus
      protractorLastCommunication
      tireGuruLastCommunication
      atmLastCommunication
      tekMetricLastCommunication
    }
  }
`;

export const GetOrganizationSecretsQuery = `
  query GetOrganizationSecrets($id: Int) {
    organizationSecrets(input: { organizationId: $id}) {
      id
      organizationId
      isTekmetricEnabled
      tekMetricClientId
      tekMetricClientSecret
      tekMetricApiEndpoint
    }
  }
`;

export const UpdateOrganizationSecretsMutation = `
  mutation UpdateOrganizationSecrets(
    $id: Int
    $isTekmetricEnabled: Boolean
    $tekMetricClientId: String
    $tekMetricClientSecret: String
    $tekMetricApiEndpoint: String
  ) {
    updateOrganizationSecrets(input: {
      id: $id
      isTekmetricEnabled: $isTekmetricEnabled
      tekMetricClientId: $tekMetricClientId
      tekMetricClientSecret: $tekMetricClientSecret
      tekMetricApiEndpoint: $tekMetricApiEndpoint
    }) {
      id
      isTekmetricEnabled
      tekMetricClientId
      tekMetricClientSecret
      tekMetricApiEndpoint
    }
  }
`;

export const GetIntegrations = `
  query Location($id: Int) {
    location(input: {
      id: $id
    }) {
      id
      zendeskGroupId
    }
    getSafeLocationSecrets(input: { sfacId: $id}) {
      id
      protractorApiKey {
        status
        description
        value
      }
      protractorAuthenticationKey {
        status
        description
        value
      }
      protractorConnectionId {
        status
        description
        value
      }
      atmUrl {
        status
        description
        value
      }
      atmUsername {
        status
        description
        value
      }
      atmPassword {
        status
        description
        value
      }
      tireGuruAuthKey {
        status
        description
        value
      }
      tireGuruAccountKey {
        status
        description
        value
      }
    }
  }
`;

export const CreateNewLocationMutation = `
  mutation CreateNewLocation(
    $name: String
    $displayName: String
    $displayLocation: String
    $appUrlSlug: String
  ) {
    createLocation(input: {
      name: $name
      displayName: $displayName
      displayLocation: $displayLocation
      active: false
      appUrlSlug: $appUrlSlug
    }) {
      id
    }
  }
`;

export const UpdateCorporateQuery = `
  mutation UpdateCorporate(
    $id: Int,
    $logoUrl: String,
    $mapPinUrl: String,
    $phone: String
    $gaTag: String
    $gtmContainerId: String
    $conversionTag: String
    $conversionTagSendTo: String
    $shopSpecialsUrl: String
    $logoImage: LocationImageInput
    $newMapPin: LocationImageInput
  ) {
    updateCorporate(input: {
      id: $id,
      logoUrl: $logoUrl
      mapPinUrl: $mapPinUrl
      phone: $phone
      gaTag: $gaTag
      gtmContainerId: $gtmContainerId
      conversionTag: $conversionTag
      conversionTagSendTo: $conversionTagSendTo
      shopSpecialsUrl: $shopSpecialsUrl
      logoImage: $logoImage
      newMapPin: $newMapPin
    }) {
      id
    }
  }
`;

export const UpdateLocationQuery = `
  mutation updateLocation(
    $organizationId: Int,
    $id: Int,
    $displayName: String,
    $displayLocation: String,
    $phone: String,
    $address: AddressInput,
    $appUrlSlug: String,
    $tirePurchaseUrl: String,
    $shopHomeUrl: String,
    $shopLogoUrl: String,
    $staffBioUrl: String,
    $pricingUrl: String,
    $phoneText: String,
    $confirmationText: String,
    $email: String,
    $active: Boolean
    $shopAppointmentsUrl: String
    $shopMainImageUrl: String
    $yelpReviewsBadge: String
    $yelpReviewsLink: String
    $yelpReviewsNumber: Int
    $googleReviewsBadge: String
    $googleReviewsLink: String
    $googleReviewsNumber: Int
    $timezone: String
    $latitude: String
    $longitude: String
    $zendeskGroupId: String
    $openingDate: String
    $locationImages: [LocationImageInput]
    $locationDailyServiceAdvisorCalls: [LocationDailyServiceAdvisorCallInput]
    $locationManagers: [UserInput]
    $locationLaborRate: LocationLaborRateInput
    $overrideCorporateLogo: Boolean
    $brands: [String]
    $equipments: [EquipmentInput]
    $laborRates: [LaborRateInput]
    $videoFeeds: [VideoFeedInput]
  ) {
    updateLocation(input: {
      organizationId: $organizationId,
      id: $id,
      displayName: $displayName,
      displayLocation: $displayLocation,
      phone: $phone,
      address: $address,
      appUrlSlug: $appUrlSlug,
      tirePurchaseUrl: $tirePurchaseUrl,
      shopHomeUrl: $shopHomeUrl,
      shopLogoUrl: $shopLogoUrl,
      staffBioUrl: $staffBioUrl,
      pricingUrl: $pricingUrl,
      phoneText: $phoneText,
      confirmationText: $confirmationText,
      email: $email,
      active: $active
      shopAppointmentsUrl: $shopAppointmentsUrl
      timezone: $timezone
      shopMainImageUrl: $shopMainImageUrl
      yelpReviewsNumber: $yelpReviewsNumber
      yelpReviewsLink: $yelpReviewsLink
      yelpReviewsBadge: $yelpReviewsBadge
      googleReviewsNumber: $googleReviewsNumber
      googleReviewsLink: $googleReviewsLink
      googleReviewsBadge: $googleReviewsBadge
      latitude: $latitude
      longitude: $longitude
      zendeskGroupId: $zendeskGroupId
      openingDate: $openingDate
      locationImages: $locationImages
      locationDailyServiceAdvisorCalls: $locationDailyServiceAdvisorCalls
      locationManagers: $locationManagers
      locationLaborRate: $locationLaborRate
      overrideCorporateLogo: $overrideCorporateLogo
      brands: $brands
      equipments: $equipments
      laborRates: $laborRates
      videoFeeds: $videoFeeds
    }) {
      id
      locationImages {
        id
        name
        url
        type
      }
    }
  }
`;

export const CreateLocationMutation = `
  mutation createLocation(
    $organizationId: Int,
    $regionId: Int,
    $districtId: Int,
    $id: Int,
    $displayName: String,
    $displayLocation: String,
    $phone: String,
    $address: AddressInput,
    $appUrlSlug: String,
    $tirePurchaseUrl: String,
    $shopHomeUrl: String,
    $shopLogoUrl: String,
    $staffBioUrl: String,
    $pricingUrl: String,
    $phoneText: String,
    $confirmationText: String,
    $email: String,
    $active: Boolean
    $shopAppointmentsUrl: String
    $shopMainImageUrl: String
    $yelpReviewsBadge: String
    $yelpReviewsLink: String
    $yelpReviewsNumber: Int
    $googleReviewsBadge: String
    $googleReviewsLink: String
    $googleReviewsNumber: Int
    $timezone: String
    $latitude: String
    $longitude: String
    $zendeskGroupId: String
    $openingDate: String
    $locationImages: [LocationImageInput]
    $locationDailyServiceAdvisorCalls: [LocationDailyServiceAdvisorCallInput]
    $locationManagers: [UserInput]
    $locationLaborRate: LocationLaborRateInput
    $overrideCorporateLogo: Boolean
    $equipments: [EquipmentInput]
    $laborRates: [LaborRateInput]
    $videoFeeds: [VideoFeedInput]
    $zendeskEnabled: Boolean
    $brands: [String]
    $integrations: LocationSecretInput
  ) {
    createLocation(input: {
      organizationId: $organizationId,
      regionId: $regionId,
      districtId: $districtId,
      id: $id,
      displayName: $displayName,
      displayLocation: $displayLocation,
      phone: $phone,
      address: $address,
      appUrlSlug: $appUrlSlug,
      tirePurchaseUrl: $tirePurchaseUrl,
      shopHomeUrl: $shopHomeUrl,
      shopLogoUrl: $shopLogoUrl,
      staffBioUrl: $staffBioUrl,
      pricingUrl: $pricingUrl,
      phoneText: $phoneText,
      confirmationText: $confirmationText,
      email: $email,
      active: $active
      shopAppointmentsUrl: $shopAppointmentsUrl
      timezone: $timezone
      shopMainImageUrl: $shopMainImageUrl
      yelpReviewsNumber: $yelpReviewsNumber
      yelpReviewsLink: $yelpReviewsLink
      yelpReviewsBadge: $yelpReviewsBadge
      googleReviewsNumber: $googleReviewsNumber
      googleReviewsLink: $googleReviewsLink
      googleReviewsBadge: $googleReviewsBadge
      latitude: $latitude
      longitude: $longitude
      openingDate: $openingDate
      locationImages: $locationImages
      locationDailyServiceAdvisorCalls: $locationDailyServiceAdvisorCalls
      locationManagers: $locationManagers
      locationLaborRate: $locationLaborRate
      overrideCorporateLogo: $overrideCorporateLogo
      equipments: $equipments
      laborRates: $laborRates
      videoFeeds: $videoFeeds
      zendeskGroupId: $zendeskGroupId
      zendeskEnabled: $zendeskEnabled
      brands: $brands
      integrations: $integrations
    }) {
      id
    }
  }
`;

const couponFragment = `
  id
  name
  title
  headline
  description
  callToActionText
  callToActionUrl
  activeDuration
  active
  requireService
  location {
    id
    displayName
    locationIndex
  }
  service {
    id
    name
    displayName
  }
`;

export const GetCouponsQuery = `
  query GetCoupons($organizationId: Int, $regionId: Int, $sfacId: Int, $isForReport: Boolean) {
    coupons(input: { organizationId: $organizationId, regionId: $regionId, sfacId: $sfacId, isForReport: $isForReport }) {
      ${couponFragment}
    }
  }
`;

export const GetCouponsReportQuery = `
  query GetCouponsReport($organizationId: Int, $regionId: Int, $sfacId: Int, $startDate: String, $endDate: String) {
    getCouponsReports(input: {
      organizationId: $organizationId
      regionId: $regionId
      sfacId: $sfacId
      startDate: $startDate
      endDate: $endDate
    }) {
      id
      usedWithoutCoupon
      res {
        couponId
        couponName
        usage_count
      }
    }
  }
`;

export const GetCouponQuery = `
  query GetCoupon($id: Int) {
    coupon(input: { id: $id }) {
      ${couponFragment}
    }
  }
`;

export const GetSFACLocationsQuery = `
  query GetSFACLocations($showInactive: Boolean, $organizationId: Int, $regionId: Int, $districtId: Int) {
    getSFACLocations(input: { showInactive: $showInactive, organizationId: $organizationId, regionId: $regionId, districtId: $districtId }) {
      id
      name
      displayName
      displayLocation
      timezone
      locationIndex
      districtId
      district {
        id
        name
      }
      region {
        id
        name
        organization {
          id
          displayName
          name
        } 
      }
      active
    }
  }
`;

export const GetSFACDistrictsQuery = `
  query GetSFACDistricts($organizationId: Int) {
    getSFACDistricts(input: {organizationId: $organizationId}) {
       id
       name
       displayName
       regionId
    }
  }
`;

const serviceFragment = `
  id
  name
  displayName
  durationMinutesHigh
  durationMinutesLow
  durationUnit
  description
  isCommon
  sortOrder
  pricingUrl
  isUsedPricingUrl
  isAvailableForWaiter
  serviceProfileName
  availabilityDays
  capability {
    id
    displayName
    name
  }
  locations {
    id
    name
  }
  serviceGroups {
    id
    name
    iconUrl
  }
  price {
        serviceName
        description
        durationString
        priceRange {
          min
          max
        }
        priceCents
        priceCertainty
        isPriceFree
      }
`;

export const GetSFACServiceQuery = `
  query GetSFACService($id: Int!) {
    service(input: {id: $id}) {
      ${serviceFragment}
    }
  }
`;

export const GetSFACServicesQuery = `
  query GetSFACServices($id: Int!) {
    services(input: {id: $id}) {
      ${serviceFragment}
    }
  }
`;

export const UpdateSFACCouponQuery = `
  mutation UpdateSFACCoupon(
    $id: Int
    $name: String
    $title: String
    $headline: String
    $description: String
    $callToActionText: String
    $callToActionUrl: String
    $activeDuration: Int
    $active: Boolean
    $sfacId: Int
    $sfacServiceId: Int
    $requireService: Boolean
  ) {
    updateCoupon(input: {
      id: $id
      name: $name
      title: $title
      headline: $headline
      description: $description
      callToActionText: $callToActionText
      callToActionUrl: $callToActionUrl
      activeDuration: $activeDuration
      active: $active
      sfacId: $sfacId
      sfacServiceId: $sfacServiceId
      requireService: $requireService
    }) {
      id
    }
  }
`;

export const CreateSFACCouponQuery = `
  mutation CreateSFACCoupon(
    $name: String
    $title: String
    $headline: String
    $description: String
    $callToActionText: String
    $callToActionUrl: String
    $activeDuration: Int
    $active: Boolean
    $organizationId: Int
    $regionId: Int
    $sfacId: Int
    $sfacServiceId: Int
  ) {
    createCoupon(input: {
      name: $name
      title: $title
      headline: $headline
      description: $description
      callToActionText: $callToActionText
      callToActionUrl: $callToActionUrl
      activeDuration: $activeDuration
      active: $active
      organizationId: $organizationId
      regionId: $regionId
      sfacId: $sfacId
      sfacServiceId: $sfacServiceId
    }) {
      id
    }
  }
`;

export const GetStaffPeriodQuery = `
  query GetStaffPeriod($sfacId: Int) {
    staffPeriods(input: {
      sfacId: $sfacId
    }) {
      id
      staff {
        id
        lastName
      }
      location {
        id
        displayName
      }
      date
      startTime
      endTime
      source
    }
  }
`;

export const GetSFACRoleQuery = `
  query GetSFACRoles {
    roles {
      id
      displayName
    }
  }
`;

export const GetStaffQuery = `
  query GetStaff {
    staffs {
      id
      lastName
      firstName
    }
  }
`;

export const GetStaffMemberQuery = `
  query GetStaffMember($id: Int) {
    staff(input: {
      id: $id
    }) {
      id
      imageUrl
      bio
      locationSpecificInformations {
        location {
          id
        }
        autoTextMeAdminId
      }
      locations {
        id
        name
      }
      roles {
        id
        name
        displayName
      }
      capabilities {
        id
        name
        displayName
      }
    }
  }
`;

export const GetCapabilitiesQuery = `
  query GetCapabilities {
    jobCapabilities {
      id
      name
      displayName
    }
  }
`;

export const UpdateServiceQuery = `
  mutation UpdateService (
    $id: Int!
    $name: String!
    $displayName: String!
    $durationMinutesHigh: Int!
    $durationMinutesLow: Int!
    $durationUnit: String
    $description: String
    $notePrompt: String
    $isCommon: Boolean
    $sortOrder: Int
    $sfacCapabilityId: Int
    $pricingUrl: String
    $isUsedPricingUrl: Boolean
    $isAvailableForWaiter: Boolean
    $sfacServiceGroupIds: [Int]
    $availabilityDays: [Int]
  ){
    updateService(input: {
      id: $id
      name: $name
      displayName: $displayName
      durationMinutesHigh: $durationMinutesHigh
      durationMinutesLow: $durationMinutesLow
      durationUnit: $durationUnit
      description: $description
      notePrompt: $notePrompt
      isCommon: $isCommon
      sortOrder: $sortOrder
      pricingUrl: $pricingUrl
      isUsedPricingUrl: $isUsedPricingUrl
      isAvailableForWaiter: $isAvailableForWaiter
      sfacCapabilityId: $sfacCapabilityId
      availabilityDays: $availabilityDays
    }) {
      id
    }
    updateSFACServiceGroupsForServices(input: {
      sfacServiceId: $id
      sfacServiceGroupIds: $sfacServiceGroupIds
    }) {
      id
    }
  }
`;

export const CreateServiceQuery = `
  mutation CreateService (
    $serviceProfileId: Int
    $name: String!
    $sortOrder: Int!
    $displayName: String!
    $durationMinutesHigh: Int!
    $durationMinutesLow: Int!
    $sfacServiceGroupId: Int!
    $isCommon: Boolean
    $sfacCapabilityId: Int
    $durationUnit: String
    $description: String
    $notePrompt: String
    $isDefault: Boolean
    $organizationId: Int
    $params: JSON
    $isPriceFree: Boolean
    $priceFunction: String
    $isUsedPricingUrl: Boolean
    $isAvailableForWaiter: Boolean
    $availabilityDays: [Int]
  ){
    createService(input: {
      name: $name
      sortOrder: $sortOrder
      serviceProfileId: $serviceProfileId
      displayName: $displayName
      durationMinutesHigh: $durationMinutesHigh
      durationMinutesLow: $durationMinutesLow
      sfacServiceGroupId: $sfacServiceGroupId
      isCommon: $isCommon
      sfacCapabilityId: $sfacCapabilityId
      durationUnit: $durationUnit
      description: $description
      notePrompt: $notePrompt
      isDefault: $isDefault
      organizationId: $organizationId
      params: $params
      priceFunction: $priceFunction
      isPriceFree: $isPriceFree
      isUsedPricingUrl: $isUsedPricingUrl
      isAvailableForWaiter: $isAvailableForWaiter
      availabilityDays: $availabilityDays
    }) {
      id
    }
  }
`;

export const GetServiceGroupsQuery = `
  query GetServiceGroups {
    serviceGroups {
      id
      name
      iconUrl
      index
    }
  }
`;
export const GetLaborRatesQuery = `
 query GetLaborRates($id: Int!) {
    getLocationLaborRates(input: {shopId: $id}) {
     name
     displayName
    }
  }
`;

export const GetReportsQuery = `
  query GetReportsQuery(
    $sfacId: Int
    $monthly: Boolean
  ) {
    getSFACReports(input: {
      sfacId: $sfacId
      monthly: $monthly
    }) {
      name
      displayName
      description
      value
      date
      detail {
        key
        value
      }
    }
  }
`;

export const GetOilTypesQuery = `
  query GetOilTypesQuery($sfacId: Int!) {
    getOilTypeByLocations (input:{
      sfacId:$sfacId
    }) {
      id
      type
      grade
      pricePerLiter
    }
  }
`;

export const GetOilTypeQuery = `
  query GetOilTypeQuery($sfacId: Int!, $id: Int!) {
    getOilTypeByLocation (input:{
      sfacId:$sfacId,
      id: $id
    }) {
      id
      type
      grade
      pricePerLiter
    }
  }
`;

export const UpdateOilTypePriceQuery = `
  mutation UpdateOilTypePriceQuery($sfacId: Int!, $id: Int!, $pricePerLiter: Int!) {
    updateOilPriceForLocation(input: {
      sfacId: $sfacId
      id: $id
      pricePerLiter: $pricePerLiter
    }) {
      id
    }
  }
`;

export const GetVehicleOilTypesQuery = `
  query GetVehicleOilTypesQuery {
    getVehicleOilTypes {
      id
      year
      make
      model
      oilTypeId
      type
      grade
    }
  }
`;

export const GetVehicleOilTypeQuery = `
  query GetVehicleOilTypeQuery ($id: Int!) {
    getVehicleOilType(input: {
      id: $id
    }) {
      id
      year
      make
      model
      oilTypeId
      type
      grade
    }
  }
`;

export const UpsertVehicleOilTypeQuery = `
  mutation UpsertVehicleOilType (
    $id: Int
    $year: Int
    $make: String
    $model: String
    $oilTypeId: Int!
  ) {
    upsertVehicleOilType(input: {
      id: $id
      year: $year
      make: $make
      model: $model
      oilTypeId: $oilTypeId
    }) {
      id
    }
  }
`;

export const GetSFACStaffQuery = `
  query GetSFACStaff(
    $sfacId: Int,
    $regionId: Int,
    $organizationId: Int,
  ) {
    getSFACStaffs(input: {
      sfacId: $sfacId
      regionId: $regionId
      organizationId: $organizationId
    }) {
      id
      phone
      beganYear
      joinedCompany
      imageUrl
      bio
      isLocationManager
      user {
        id
        firstName
        lastName
        internalId
      }
      isSynchronisedWithSource
      locationSpecificInformations {
        autoTextMeAdminId
        location {
          id
        }
      }
      locations {
        id
        name
      }
      capabilities {
        id
        name
        displayName
      }
      roles {
        id
        name
        displayName
      }
      scheduledThrough {
        date
        source
      }
    }
  }
`;

export const UpsertSFACStaffMutation = `  
  mutation UpsertSFACStaff(
    $id: Int
    $sfacId: Int
    $imageUrl: String
    $bio: String
    $phone: String
    $beganYear: Int
    $joinedCompany: String
    $locations: [SFACStaffLocationInput]
    $capabilities: [Int]
    $roles: [Int]
    $uploadOriginalImage: String
    $uploadResizedImage: String
    $isLocationManager: Boolean
  ){
    upsertSFACStaff(input: {
      id: $id
      sfacId: $sfacId
      imageUrl: $imageUrl
      bio: $bio
      phone: $phone
      beganYear: $beganYear
      joinedCompany: $joinedCompany
      locations: $locations
      capabilities: $capabilities
      roles: $roles
      uploadOriginalImage: $uploadOriginalImage
      uploadResizedImage: $uploadResizedImage
      isLocationManager: $isLocationManager
    }) {
      id
    }
  }
`;

export const GetSFACLocationsByRegionsQuery = `
  query GetSFACLocationsByRegionsQuery($id: Int) {
    getSFACLocationsByRegions(input: {id: $id}) {
       regions {
          id
          name
          displayName
          locations {
            id
            displayLocation
            locationIndex
          }
          regionalManagers {
            id
            firstName
            lastName
          }
       }
       locationsWithoutRegion {
          id
          displayLocation
          locationIndex
       }
    }
  }
`;

export const GetSFACDistrictsByRegionsQuery = `
  query GetSFACDistrictsByRegionsQuery($id: Int) {
    getSFACDistrictsByRegions(input: {id: $id}) {
       regions {
          id
          name
          displayName
          districts {
            id
            name
            displayName
          }
          regionalManagers {
            id
            firstName
            lastName
          }
       }
       districtsWithoutRegion {
         id
         displayName
         name
       }
    }
  }
`;

export const GetDistrictsQuery = `
  query GetDistrictsQuery($id: Int) {
    districts(input: {id: $id}) {
      id
      name
      displayName
    }
  }
`;

export const GetSFACLocationsByDistrictsQuery = `
  query GetSFACLocationsByDistrictsQuery($regionId: Int) {
    getSFACLocationsByDistricts(input: {regionId: $regionId}) {
       districts {
          id
          name
          displayName
          locations {
            id
            displayLocation
            locationIndex
          }
          districtManagers {
            id
            firstName
            lastName
          }
       }
       locationsWithoutDistrict {
          id
          displayLocation
          locationIndex
       }
    }
  }
`;

export const GetRegionsQuery = `
  query GetRegionsQuery($id: Int) {
    regions(input: {id: $id}) {
      id
      name
      displayName
    }
  }
`;

export const GetOrganizationsQuery = `
  query GetOrganizationsQuery {
    organizations {
      id
      name
      displayName
      organizationOwners {
        id
        firstName
        lastName
      }
    }
  }
`;

export const GetOrganizationAreasQuery = `
  query GetOrganizationAreasQuery($organizationId: Int) {
    getOrganizationAreas(input: {organizationId: $organizationId}) {
      organizations {
        id
        name
        displayName
      }
      regions {
        id
        name
        displayName
      }
      districts {
        id
        name
        displayName
      }
      locations {
        id
        name
        displayName
        displayLocation
        locationIndex
      }
    }
  }
`;

export const CreateOrganizationQuery = `
  mutation CreateOrganization(
    $displayName: String
    $name: String
    $organizationOwners: [UserInput]
    $inviteOwners: [String]
  ) {
    createOrganization(input: {
      displayName: $displayName
      name: $name
      organizationOwners: $organizationOwners
      inviteOwners: $inviteOwners
    }) {
      id
      name
      displayName
      organizationOwners {
        id
        firstName
        lastName
      }
    }
  }
`;

export const UpdateOrganizationQuery = `
  mutation UpdateOrganization(
    $id: Int
    $displayName: String
    $organizationOwners: [UserInput]
    $inviteOwners: [String]
  ) {
    updateOrganization(input: {
      id: $id
      displayName: $displayName
      organizationOwners: $organizationOwners
      inviteOwners: $inviteOwners
    }) {
      id
      name
      displayName
      organizationOwners {
        id
        firstName
        lastName
      }
    }
  }
`;

export const ArchiveOrganizationMutation = `
  mutation ArchiveOrganization($id: Int!) {
    archiveOrganization(input: { id: $id }) {
      id
    }
  }
`;

export const CreateRegionQuery = `
  mutation CreateRegion(
    $organizationId: Int
    $name: String
    $displayName: String
    $districts: [DistrictInput]
    $regionalManagers: [UserInput]
  ) {
    createRegion(input: {
      organizationId: $organizationId
      name: $name
      displayName: $displayName
      districts: $districts
      regionalManagers: $regionalManagers
    }) {
      id
    }
  }
`;

export const UpdateRegionQuery = `
  mutation UpdateRegion(
    $id: Int
    $name: String
    $displayName: String
    $districts: [DistrictInput]
    $regionalManagers: [UserInput]
  ) {
    updateRegion(input: {
      id: $id
      name: $name
      displayName: $displayName
      districts: $districts
      regionalManagers: $regionalManagers
    }) {
      id
      name
      displayName
      districts {
        id
        name
        displayName
      }
      regionalManagers {
        id
      }
    }
  }
`;

export const CreateDistrictQuery = `
  mutation CreateDistrict(
    $id: Int
    $organizationId: Int
    $name: String
    $displayName: String
    $locations: [LocationInput]
    $districtManagers: [UserInput]
  ) {
    createDistrict(input: {
      id: $id
      organizationId: $organizationId
      name: $name
      displayName: $displayName
      locations: $locations
      districtManagers: $districtManagers
    }) {
      id
    }
  }
`;

export const UpdateDistrictQuery = `
  mutation UpdateDistrict(
    $id: Int
    $name: String
    $displayName: String
    $locations: [LocationInput]
    $districtManagers: [UserInput]
  ) {
    updateDistrict(input: {
      id: $id
      name: $name
      displayName: $displayName
      locations: $locations
      districtManagers: $districtManagers
    }) {
      id
      name
      displayName
      locations {
        id
        displayLocation
      }
      districtManagers {
        id
      }
    }
  }
`;

export const ArchiveRegionMutation = `
  mutation ArchiveRegion($id: Int!) {
    archiveRegion(input: { id: $id }) {
      id
    }
  }
`;

export const ArchiveDistrictMutation = `
  mutation ArchiveDistrict($id: Int!) {
    archiveDistrict(input: { id: $id }) {
      id
    }
  }
`;

export const GetSFACRolesQuery = `
  query GetSFACRoles {
    getSFACRoles {
      id
      name
      displayName
    }
  }
`;

export const GetSFACCapabilitiesQuery = `
  query GetSFACJobCapabilities {
    jobCapabilities {
      id
      name
      displayName
    }
  }
`;

export const ArchiveStaffMutation = `
  mutation ArchiveStaff($id: Int!) {
    archiveStaff(input: { id: $id }) {
      id
    }
  }
`;

export const GetLocationVehicleOilLaborRateQuery = `
  query LocationVehicleOilLaborRates($sfacId: Int!) {
    getVehicleOilLaborRateByLocations(input:{
      sfacId: $sfacId
    }) {
      id
      year
      make
      model
      laborRate
      location {
        id
      }
    }
  }
`;

export const GetVehicleOilLaborQuery = `
  query LocationVehicleOilLaborRate(
    $id: Int!
    $sfacId: Int
  ) {
    vehicleLocationOilLaborRate(input: {
      id: $id
      sfacId: $sfacId
    }) {
      id
      year
      make
      model
      laborRate
    }
  }
`;

export const CreateVehicleLocationOilLaborRateQuery = `
  mutation CreateVehicleLocationOilLaborRate(
    $year: Int
    $make: String
    $model: String
    $sfacId: Int
    $laborRate: Int
  ) {
    createVehicleLocationOilLaborRate(input: {
      year: $year
      make: $make
      model: $model
      sfacId: $sfacId
      laborRate: $laborRate
    }) {
      id
    }
  }
`;

export const UpdateVehicleLocationOilLaborRateQuery = `
  mutation UpdateVehicleLocationOilLaborRate(
    $id: Int
    $year: Int
    $make: String
    $model: String
    $sfacId: Int
    $laborRate: Int
  ) {
    updateVehicleLocationOilLaborRate(input: {
      id: $id
      year: $year
      make: $make
      model: $model
      sfacId: $sfacId
      laborRate: $laborRate
    }) {
      id
    }
  }
`;

export const ArchiveVehicleLocationOilLaborRateQuery = `
  mutation ArchiveVehicleLocationOilLaborRate(
    $id: Int
  ) {
    deleteVehicleLocationOilLaborRate(input: {
      id: $id
    }) {
      id
    }
  }
`;

export const UpdateLastAcceptanceHourForLocationQuery = `
  mutation LastAcceptanceHour(
    $sfacId: Int!
    $lastAcceptanceHour: Int!
  ) {
    lastAcceptanceHour(input: {
      sfacId: $sfacId
      lastAcceptanceHour: $lastAcceptanceHour
    }) {
      lastAcceptanceHour
    }
  }
`;

export const UpdateFirstAcceptanceHourForLocationQuery = `
  mutation FirstAcceptanceHour(
    $sfacId: Int!
    $firstAcceptanceHour: Int!
  ) {
    firstAcceptanceHour(input: {
      sfacId: $sfacId
      firstAcceptanceHour: $firstAcceptanceHour
    }) {
      firstAcceptanceHour
    }
  }
`;

export const UpdateAppointmentsAcceptanceStartDayQuery = `
  mutation AppointmentsAcceptanceStartDay(
    $sfacId: Int!
    $acceptanceStartDay: Int!
  ) {
    appointmentsAcceptanceStartDay(input: {
      sfacId: $sfacId
      acceptanceStartDay: $acceptanceStartDay
    }) {
      acceptanceStartDay
    }
  }
`;

export const UpdateShopWaitersControlDataQuery = `
  mutation ShopWaitersControlData(
    $sfacId: Int!
    $isShopTakesWaiters: Boolean
    $isAllowSameDayWaiters: Boolean
    $sameDayAppointmentBufferTime: Int
  ) {
    locationWaitersControl(input: {
      sfacId: $sfacId
      isShopTakesWaiters: $isShopTakesWaiters
      isAllowSameDayWaiters: $isAllowSameDayWaiters
      sameDayAppointmentBufferTime: $sameDayAppointmentBufferTime
    }) {
      isShopTakesWaiters
      isAllowSameDayWaiters
      sameDayAppointmentBufferTime
    }
  }
`;

export const UpdateLocationSchedulingRulesMutation = `
  mutation UpdateLocationSchedulingRules(
    $sfacId: Int!
    $maxVehicleDropOff: Int
    $isSmartSchedulingOn: Boolean
    $maxWaitersCount: Int
  ) {
    updateLocationSchedulingRules(input: {
      sfacId: $sfacId
      maxVehicleDropOff: $maxVehicleDropOff
      isSmartSchedulingOn: $isSmartSchedulingOn
      maxWaitersCount: $maxWaitersCount
    }) {
      maxVehicleDropOff
      isSmartSchedulingOn
      maxWaitersCount
    }
  }
`;

export const UpdateAppointmentsAcceptanceEndDayQuery = `
  mutation AppointmentsAcceptanceStartDay(
    $sfacId: Int!
    $acceptanceEndDay: Int!
  ) {
    appointmentsAcceptanceEndDay(input: {
      sfacId: $sfacId
      acceptanceEndDay: $acceptanceEndDay
    }) {
      acceptanceEndDay
    }
  }
`;

export const UpdateHowLateDropOffsCanOccurQuery = `
  mutation UpdateHowLateDropOffsCanOccur(
    $sfacId: Int!
    $howLateDropOffsCanOccur: Int!
  ) {
    howLateDropOffsCanOccur(input: {
      sfacId: $sfacId
      howLateDropOffsCanOccur: $howLateDropOffsCanOccur
    }) {
      howLateDropOffsCanOccur
    }
  }
`;

export const GetRulesFromLocationQuery = `
  query Location($id: Int) {
    location(input: {
      id: $id
    }) {
      firstAcceptanceHour
      lastAcceptanceHour
      howLateDropOffsCanOccur
      maxVehicleDropOff
      isSmartSchedulingOn
      maxWaitersCount
      isShopTakesWaiters
      isAllowSameDayWaiters
      acceptanceStartDay
      acceptanceEndDay
      sameDayAppointmentBufferTime
    }
  }
`;

export const GetIntegrationStatusQuery = `
  query GetIntegrationStatus($sfacId: Int!) {
    getIntegrationStatus(input: {
      sfacId: $sfacId
    }) {
      protractor
      autotextme
    }
  }
`;

export const UpdateSecretForLocationMutation = `
  mutation UpdateSecretsForLocation(
    $sfacId: Int!,
    $protractorAuthenticationKey: String,
    $protractorApiKey: String,
    $protractorConnectionId: String,
    $atmUrl: String,
    $atmUsername: String,
    $atmPassword: String,
    $tireGuruAuthKey: String,
    $tireGuruAccountKey: String,
    $tekMetricClientId: String,
    $tekMetricClientSecret: String,
    $tekMetricShopId: String,
    $protractorEnabled: Boolean,
    $atmEnabled: Boolean,
    $tireGuruEnabled: Boolean,
    $tekMetricEnabled: Boolean,
    $zendeskEnabled: Boolean,
    $zendeskGroupId: String,
  ) {
    updateSecretsForLocation(input: {
      sfacId: $sfacId,
      protractorAuthenticationKey: $protractorAuthenticationKey,
      protractorApiKey: $protractorApiKey,
      protractorConnectionId: $protractorConnectionId,
      atmUrl: $atmUrl,
      atmUsername: $atmUsername,
      atmPassword: $atmPassword,
      tireGuruAuthKey: $tireGuruAuthKey,
      tireGuruAccountKey: $tireGuruAccountKey,
      tekMetricClientId: $tekMetricClientId,
      tekMetricClientSecret: $tekMetricClientSecret,
      tekMetricShopId: $tekMetricShopId,
      protractorEnabled: $protractorEnabled,
      atmEnabled: $atmEnabled,
      tireGuruEnabled: $tireGuruEnabled,
      tekMetricEnabled: $tekMetricEnabled,
      zendeskEnabled: $zendeskEnabled,
      zendeskGroupId: $zendeskGroupId,
    }) {
      id
    }
  }
`;

export const GetLocationAllInfoQuery = `
  query (
    $sfacId: Int!
    $name: String
    $organizationId: Int
    ) {
    location(input: {
      id: $sfacId
    }) {
      id
      name
      displayName
      displayLocation
      phone
      address {
        id
        street
        city
        state
        zipcode
      }
      appUrlSlug
      tirePurchaseUrl
      shopHomeUrl
      shopLogoUrl
      phoneText
      email
      shopAppointmentsUrl
      timezone
      latitude
      longitude
      yelpReviewsBadge
      yelpReviewsLink
      yelpReviewsNumber
      googleReviewsBadge
      googleReviewsLink
      googleReviewsNumber
      zendeskGroupId
      active
      openingDate
      services {
        id
        name
        displayName
      }
    }
    corporate(input: {
       organizationId: $organizationId
    }) {
      id
      logoUrl
      phone
      gaTag
      conversionTag
      conversionTagSendTo
      shopSpecialsUrl
    }
    getSafeLocationSecrets(input: { sfacId: $sfacId}) {
      id
      protractorApiKey {
        status
        description
      }
      protractorAuthenticationKey {
        status
        description
      }
      protractorConnectionId {
        status
        description
      }
      atmUrl {
        status
        description
      }
      atmUsername {
        status
        description
      }
      atmPassword {
        status
        description
      }
      tireGuruAuthKey {
        status
        description
      }
      tireGuruAccountKey {
        status
        description
      }
    }

    getIntegrationStatus(input: {
        sfacId: $sfacId
      }) {
        protractor
        autotextme
    }

    staffPeriods(input: {
      sfacId: $sfacId
    }) {
      id
      staff {
        id
        lastName
      }
      location {
        id
        displayName
      }
      date
      startTime
      endTime
      source
    }

    getSFACStaffs(input: {
      sfacId: $sfacId
    }) {
      id
      imageUrl
      bio
      locationSpecificInformations {
        location {
          id
        }
      }
      locations {
        id
        name
      }
      capabilities {
        id
        name
        displayName
      }
      roles {
        id
        name
        displayName
      }
    }

    getSFACScheduleByLocations(input: {
      sfacId: $sfacId
    }) {
      id
      date
      closed
      startTime
      startBreak
      endBreak
      endTime
    }

    getServicePriceByLocations(input: {
      sfacId: $sfacId
    }) {
      id
    }

    getVehicleBrands(input: {
      sfacId: $sfacId
    }) {
      brand
    }

    getOilTypeByLocations (input:{
      sfacId: $sfacId
    }) {
      id
      type
      grade
      pricePerLiter
    }

    getVehicleOilLaborRateByLocations(input:{
      sfacId: $sfacId
    }) {
      id
      year
      make
      model
      laborRate
      location {
        id
      }
    }
    
    getSFACNamedReport(input: {
      sfacId: $sfacId
      name: $name
    }) {
      name
      value
    }

    getLocationPeriodTemplatesByLocations(input: {
      sfacId: $sfacId
    }) {
      id
      totalHoursOpen
      closed
    }
  }
`;

export const UnsubscribeUserEmailMutation = `
mutation($userEmailList: [String]){
  unsubscribeUserEmails(input: {
    userEmailList: $userEmailList
  }){
    userEmailList
  }
}`;

export const GetUserQuery = `
  query GetUserQuery( $id: Int ) {
    user(input: { id: $id }) {
      id
      internalId
      created_at,
      updated_at,
      archived,
      lastActivity,
      isRemoteTeamMember
      firstName
      lastName
      email
      staff {
        id
        phone
        beganYear
        bio
        joinedCompany
        roles {
          id
          name
          displayName
        }
        capabilities {
          id
          name
          displayName
        }
      }
      accessLevels {
        id
        name
        displayName
        rank
      }
      managerOfDistricts {
        id
        name
        displayName
      }      
      managerOfLocations {
        id
        name
        displayName
        displayLocation
        locationIndex
      }      
      assistantGeneralManagerOfLocations {
        id
        name
        displayName
        displayLocation
        locationIndex
      }
      serviceAdvisorOfLocations {
        id
        name
        displayName
        displayLocation
        locationIndex
      }
      technicianOfLocations {
        id
        name
        displayName
        displayLocation
        locationIndex
      }
      managerOfRegions {
        id
        name
        displayName
      }
      customerSupportOfRegions {
        id
        name
        displayName
      }
      customerSupportOfOrganizations {
        id
        name
        displayName
      }
      ownerOfOrganizations {
        id
        name
        displayName
      }
      adminOfOrganizations {
        id
        name
        displayName
      }
    }
  }
`;

export const GetUsersQuery = `
  query GetUsersQuery(
    $organizationId: Int,
    $regionId: Int,
    $districtId: Int,
    $selectedRole: String,
    $selectedStatus: String,
    $search: String,
    $selectedWorkType: String,
    $accessLevels: [AccessLevelInput]
    ) {
    users(input: {
      organizationId: $organizationId
      regionId: $regionId
      districtId: $districtId
      selectedRole: $selectedRole,
      selectedStatus: $selectedStatus,
      accessLevels: $accessLevels
      search: $search,
      selectedWorkType: $selectedWorkType,
  }) {
      id
      internalId
      firstName
      lastName
      email
      lastActivity
      isRemoteTeamMember
      archived
      created_at
      staff {
        id
        joinedCompany
      }
      accessLevels {
        id
        name
        displayName
        rank   
      }
      managerOfLocations {
        id
        name
        locationIndex
        organization {
            id
            name
        }
      }
      assistantGeneralManagerOfLocations {
        id
        name
        locationIndex
        organization {
            id
            name
        }
      }
      serviceAdvisorOfLocations {
        id
        name
        locationIndex
        organization {
            id
            name
        }
      }
      technicianOfLocations {
        id
        name
        locationIndex
        organization {
            id
            name
        }
      }
      managerOfDistricts {
        id
        name
      }
      managerOfRegions {
        id
        name
      }
      ownerOfOrganizations {
        id
        name
        displayName
      }
      customerSupportOfRegions {
        id
        name
      }
      customerSupportOfOrganizations {
        id
        name
        displayName
      }
      adminOfOrganizations {
        id
        name
        displayName
      }
    }
  }
`;

export const CreateUserQuery = `
  mutation CreateUser(
    $firstName: String
    $lastName: String
    $email: String
    $isRemoteTeamMember: Boolean
    $organizationId:  Int
    $regionId:  Int
    $accessLevels: [AccessLevelInput]
    $userRoles: JSON
    $staffData: JSON
  ) {
    createUser(input: {
      firstName: $firstName
      lastName: $lastName
      email: $email
      isRemoteTeamMember: $isRemoteTeamMember
      organizationId: $organizationId
      regionId: $regionId
      accessLevels: $accessLevels
      userRoles: $userRoles
      staffData: $staffData
    }) {
      id
    }
  }
`;

export const UpdateUserQuery = `
  mutation UpdateUser(
    $id: Int
    $firstName: String
    $lastName: String
    $email: String
    $isRemoteTeamMember: Boolean
    $regionId: Int
    $organizationId: Int
    $accessLevels: [AccessLevelInput]
    $userRoles: JSON
    $staffData: JSON
  ) {
    updateUser(input: {
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      isRemoteTeamMember: $isRemoteTeamMember
      regionId: $regionId
      organizationId: $organizationId
      accessLevels: $accessLevels
      userRoles: $userRoles
      staffData: $staffData
    }) {
      id
      firstName
      lastName
      email
      accessLevels {
        id
        name
        displayName
        rank
      }
    }
  }
`;

export const UpdateUserPasswordMutation = `
  mutation UpdateUserPassword(
    $password: String, 
    $token: String
  ) {
    updateUserPassword(input: {
      password: $password
      token: $token
    }) {
      token
      email
    }
  }
`;

export const ForgotPasswordMutation = `
  mutation ForgotPassword(
    $email: String
  ) {
    forgotPassword(input: {
      email: $email
    }) {
      email
    }
  }
`;

export const GetAccessLevelsQuery = `
  query GetAccessLevelsQuery {
    accessLevels {
      id
      name
      displayName
      rank
    }
  }
`;

export const ArchiveUserMutation = `
  mutation ArchiveUserMutation($id: Int!) {
    archiveUser(input: { id: $id }) {
      id
    }
  }
`;

export const RestoreUserMutation = `
  mutation RestoreUserMutation($userId: Int) {
    restoreUser(input: { userId: $userId }) {
      id
    }
  }
`;

export const GetCorporateQuery = `
  query Corporate($id: Int) {
    corporate(input: {
      organizationId: $id
    }) {
      id 
      phone
      logoUrl
      gaTag
      gtmContainerId
      conversionTag
      conversionTagSendTo
      shopSpecialsUrl
      mapPinUrl
    }
  }
`;

export const GetEquipmentTypesQuery = `
  query GetEquipmentTypes($organizationId: Int) {
    equipmentTypes(input: {
      organizationId: $organizationId
    }) {
      id
      name
      capabilityId
    }
  }
`;

export const UpdateEquipmentTypes = `
  mutation updateEquipmentType(
    $organizationId: Int,
    $equipmentTypes: [EquipmentTypeInput]
   ) {
    updateEquipmentType(input: {
      organizationId: $organizationId,
      equipmentTypes: $equipmentTypes,
    }) {
      id
    }
  }
`;

export const GetServiceProfilesQuery = `
  query GetServiceProfiles(
    $ownerId: Int
    $ownerType: String
    $owners: [OwnerInput]
  )  {
    serviceProfiles(input: {
      ownerId: $ownerId
      ownerType: $ownerType
      owners: $owners
    }) {
      id
      name
      ownerId
      ownerType
      owner
      updated_at
      updatedUser {
        id
        firstName
        lastName
      }
    }
  }
`;

/* export const GetServiceProfileQuery = `
query GetServiceProfile(
    $id: Int!
  ) {
    serviceProfile(input: {
      id: $id
    }) {
      id
      name
      ownerId
      ownerType
      services {
        id
        name
        displayName
      }
    }
  }` */

export const GetServiceProfileQuery = `
query GetServiceProfile(
    $id: Int
    $ownerId: Int
    $ownerType: String
  ) {
    serviceProfile(input: {
      id: $id
      ownerId: $ownerId
      ownerType: $ownerType
    }) {
      id
      name
      ownerId
      ownerType
      originServiceProfileName
      services {
       service {
        id
        name
        displayName
        durationMinutesHigh
        durationMinutesLow
        durationUnit
        description
        notePrompt
        isCommon
        sortOrder
        isUsedPricingUrl
        pricingUrl
        availabilityDays
        isAvailableForWaiter
        capability {
         id
         name
         displayName
        }
        serviceGroups {
         id
         name
         iconUrl
        }    
       }
       price {
         name
         displayName
         isPriceFree
         priceFunction
         laborRateType
         params
         description
         durationString
         priceCents
         priceCertainty
         priceRange {
           max
           min
         }
       }
     }
    }
  }`;

export const CreateServiceProfileMutation = `
  mutation CreateServiceProfile(
    $name: String
    $ownerId: Int
    $ownerType: String
    $services: [ServiceProfileServiceInput]
  ) {
    createServiceProfile(input: {
      name: $name
      ownerId: $ownerId
      ownerType: $ownerType
      services: $services
    }) {
      id
    }
  }
`;

export const UpdateServiceProfileGeneralDataMutation = `
  mutation UpdateServiceProfileGeneralData(
    $id: Int
    $name: String
  ) {
    updateServiceProfileGeneralData(input: {
      id: $id
      name: $name
    }) {
      id
      name
    }
  }
`;

export const DeleteServiceProfileMutation = `
  mutation DeleteServiceProfile(
    $id: Int
  ) {
    deleteServiceProfile(input: {
      id: $id
    }) {
      id
    }
  }
`;

export const GetDefaultServicesQuery = `
  query GetDefaultServices(
    $organizationId: Int
  ) {
    getDefaultServices (input: {
      organizationId: $organizationId
    }) {
      service {
        id
        name
        displayName
        durationMinutesHigh
        durationMinutesLow
        durationUnit
        description
        notePrompt
        isCommon
        sortOrder
        isUsedPricingUrl
        availabilityDays
        isAvailableForWaiter
        updated_at
        capability {
         id
         name
         displayName
        }
        serviceGroups {
         id
         name
         iconUrl
        }    
      }
      price {
        name
        displayName
        isPriceFree
        priceFunction
        laborRateType
        params
      }
    }
  }
`;

export const PartialRedeployServiceProfileToLocationMutation = `
  mutation PartialRedeployServiceProfileToLocationMutation($serviceProfileId: Int!, $locationIds: [Int], $serviceIds: [Int]) {
    partialRedeployServiceProfileToLocation(input: { serviceProfileId: $serviceProfileId, locationIds: $locationIds, serviceIds: $serviceIds }) {
      id
    }
  }
`;

export const PartialRedeployServiceProfileToOtherServiceProfilesMutation = `
  mutation PartialRedeployServiceProfileToOtherServiceProfilesMutation($serviceProfileId: Int!, $serviceProfiles: [ServiceProfilesToBeRedeployedInput], $serviceIds: [Int]) {
    partialRedeployServiceProfileToOtherServiceProfiles(input: { serviceProfileId: $serviceProfileId, serviceProfiles: $serviceProfiles, serviceIds: $serviceIds }) {
      id
    }
  }
`;

export const DuplicateServiceProfileMutation = `
  mutation DuplicateServiceProfile(
    $id: Int!
  ) {
    duplicateServiceProfile(input: {
      id: $id
    }) {
      id
    }
  }
`;

export const DuplicateServiceProfileToOtherRegionsMutation = `
  mutation DuplicateServiceProfileToOtherRegions(
    $id: Int!
    $regionsIds: [Int]!
  ) {
    duplicateServiceProfileToOtherRegions(input: {
      id: $id
      regionsIds: $regionsIds
    }) {
      id
    }
  }
`;

export const DeleteServiceMutation = `
  mutation DeleteService(
    $id: Int!
  ) {
    deleteService(input: {
      id: $id
    }) {
      id
    }
  }
`;

export const DeleteCouponMutation = `
  mutation DeleteCoupon(
    $id: Int
  ) {
    deleteCoupon(input: {
      id: $id
    }) {
      id
    }
  }
`;

export const BatchServicesUpdateMutation = `
  mutation BatchUpdateServices(
    $ownerId: Int
    $ownerType: String
    $services: [BatchUpdateServiceInput]
  ) {
    batchUpdateServices(input: {
      ownerId: $ownerId
      ownerType: $ownerType
      services: $services
    }) {
      id
    }
  }
`;

export const GetOrganizationSettingsQuery = `
  query GetOrganizationSettings($organizationId: Int) {
    organizationSettings(input: {
      organizationId: $organizationId
    }) {
      organizationId 
      isCustomerEmailAutomatic
      isWeeklyEmailsEnabled
      isTransactionalEmailsEnabled
      vehicleDB
      availabilityAPI
      appointmentsSourceOptions
    }
  }
`;

export const UpdateOrganizationSettingsMutation = `
  mutation UpdateOrganizationSettings(
      $organizationId: Int
      $isCustomerEmailAutomatic: Boolean
      $isTransactionalEmailsEnabled: Boolean
      $isWeeklyEmailsEnabled: Boolean
      $vehicleDB: String
      $appointmentsSourceOptions: [String]
  ) {
    updateOrganizationSettings(input: {
      organizationId: $organizationId
      isCustomerEmailAutomatic: $isCustomerEmailAutomatic
      isTransactionalEmailsEnabled: $isTransactionalEmailsEnabled
      isWeeklyEmailsEnabled: $isWeeklyEmailsEnabled
      vehicleDB: $vehicleDB
      appointmentsSourceOptions: $appointmentsSourceOptions
    }) {
      organizationId 
      isCustomerEmailAutomatic
      isTransactionalEmailsEnabled
      isWeeklyEmailsEnabled
      appointmentsSourceOptions
    }
  }
`;

export const UpdateOrganizationMutation = `
  mutation UpdateOrganization(
    $id: Int
    $name: String
    $displayName: String
    $organizationOwners: [UserInput]
  ) {
    updateOrganization(input: {
      id: $id
      name: $name
      displayName: $displayName
      organizationOwners: $organizationOwners
    }) {
      id
      name
      displayName
    }
  }
`;

export const GetUserViewModeQuery = `
  query GetUserViewModeQuery($userId: Int) {
    userViewMode(input: {userId: $userId}) {
      id
      userId
      location {
        id
        name
        displayName
        displayLocation
        timezone
        locationIndex
        region {
          id
          name
          organization {
            id
            displayName
            name
          } 
        }
        active
      }
      district {
       id
       name
       displayName
       locations {
         id
         locationIndex
         displayLocation
       }
      }
      region {
       id
       name
       displayName
       locations {
            id
            locationIndex
            displayLocation
       }
      }
      organization {
        id
        name
        displayName
        regions {
            id
            name
            displayName
        }
        locations {
             id
             locationIndex
             displayLocation
        }
      }
    }
  }
`;

export const UpdateUserViewModeMutation = `
  mutation UpdateUserViewMode(
    $id: Int
    $userId: Int
    $locationId: Int
    $districtId: Int
    $regionId: Int
    $organizationId: Int
  ) {
    updateUserViewMode(input: {
      id: $id
      userId: $userId
      locationId: $locationId
      regionId: $regionId
      districtId: $districtId
      organizationId: $organizationId
    }) {
      id
    }
  }
`;

export const SetUserFirstAndLastNameMutation = `
 mutation SetUserFirstAndLastName(
    $id: Int
    $firstName: String
    $lastName: String
  ) {
    setUserFirstAndLastName(input: {
      id: $id
      firstName: $firstName
      lastName: $lastName
    }) {
      id
    }
  }
`;

export const GetStaffPeriodTemplateQuery = `
  query GetStaffPeriodTemplateQuery(
    $locationId: Int!
    $staffId: Int!
  ) {
    getStaffPeriodTemplatesByLocationAndStaffs(input: {
      locationId: $locationId
      staffId: $staffId
    }) {
      id
      day
      off
      startTimeLocalHour
      startTimeMinute
      startBreakLocalHour
      startBreakMinute
      endBreakLocalHour
      endBreakMinute
      endTimeLocalHour
      endTimeMinute
    }
  }
`;

export const CreateOrUpdateStaffTemplatePeriodQuery = `
  mutation CreateOrUpdateStaffPeriodTemplate(
    $templates: [CreateOrUpdateStaffPeriodTemplateInput!]!
    $locationId: Int!
    $staffId: Int!
  ) {
    updateStaffPeriodTemplate(input: {
     templates: $templates
     locationId: $locationId
     staffId: $staffId
    }) {
      id
    }
  }
`;

export const GetOrganizationHolidaysAndClosuresQuery = `
  query GetOrganizationHolidaysAndClosuresQuery(
    $organizationId: Int
  ) {
    getOrganizationHolidaysAndClosures(input: {
      organizationId: $organizationId
    }) {
      id
      date
      closed
      organizationId
      startTime
      endTime
    }
  }
`;

export const GetVehicleAppointmentsReportQuery = `
  query GetVehicleAppointmentsReportQuery(
    $organizationId: Int
    $regionId: Int
    $sfacId: Int
    $from: String
    $to: String
  ) {
    getVehicleAppointmentsReport(input: {
      organizationId: $organizationId
      regionId: $regionId
      sfacId: $sfacId
      from: $from
      to: $to
    }) {
      data {
        name
        appointments
        locationPercentage
        regionPercentage
        organizationPercentage
      }
    }
  }
`;

export const GetAppointmentsReportQuery = `
  query GetAppointmentsReportQuery(
    $organizationId: Int
    $regionId: Int
    $sfacId: Int
    $from: String
    $to: String
  ) {
    getAppointmentsReport(input: {
      organizationId: $organizationId
      regionId: $regionId
      sfacId: $sfacId
      from: $from
      to: $to
    }) {
      data {
        name
        appointments
        invoicedAppointments
        invoicedAppointmentsTotalAmount
        invoicedAppointmentsAverage
        noShowPercentage
        locationPercentage
        regionPercentage
        organizationPercentage
      }
    }
  }
`;

export const GetAppointmentsInternalIdReportQuery = `
  query GetAppointmentsInternalIdReportQuery(
    $organizationId: Int
    $regionId: Int
    $sfacId: Int
    $from: String
    $to: String
  ) {
    getAppointmentsInternalIdReport(input: {
      organizationId: $organizationId
      regionId: $regionId
      sfacId: $sfacId
      from: $from
      to: $to
    }) {
      data {
        name
        imageUrl
        internalId
        appointments
        invoicedAppointments
        invoicedAppointmentsTotalAmount
        invoicedAppointmentsAverage
        noShowPercentage
        servicesAvg
      }
    }
  }
`;

export const GetAppointmentsBookingByIdReportQuery = `
  query GetAppointmentsBookingByIdReportQuery(
    $organizationId: Int
    $regionId: Int
    $sfacId: Int
    $from: String
    $to: String
  ) {
    getAppointmentsBookingByIdReport(input: {
      organizationId: $organizationId
      regionId: $regionId
      sfacId: $sfacId
      from: $from
      to: $to
    }) {
      data {
        name
        imageUrl
        internalId
        appointments
        bookingToAppointment
        sameDayService
        dropOffs
        firstTime
        returning
        longTerm
      }
    }
  }
`;

export const GetBookingServicesReportQuery = `
  query GetBookingServicesReportQuery(
    $organizationId: Int
    $regionId: Int
    $sfacId: Int
    $from: String
    $to: String
  ) {
    getBookingServicesReport(input: {
      organizationId: $organizationId
      regionId: $regionId
      sfacId: $sfacId
      from: $from
      to: $to
    }) {
      data {
        name
        services
        locationPercentage
        regionPercentage
        organizationPercentage
      }
    }
  }
`;

export const GetHealthCheckDataQuery = `
  query GetHealthCheckDataQuery($organizationId: Int, $regionId: Int, $districtId: Int, $sfacId: Int) {
    getHealthCheckData(input: {
      organizationId: $organizationId
      regionId: $regionId
      districtId: $districtId
      sfacId: $sfacId
    }) {
       data
    }
  }
`;

export const GetFollowUpsReportQuery = `
  query GetFollowUpsReportQuery($sfacId: Int, $regionId: Int, $organizationId: Int, $from: String, $to: String) {
    getFollowUpsReport(input: {
      sfacId: $sfacId, 
      regionId: $regionId, 
      organizationId: $organizationId,
      from: $from,
      to: $to,
    }) {
      data {
        userId
        userName
        twoDayFollowUpCount
        sixMonthFollowUpCount
        twoDayCallResults
        sixMonthCallResults
      }
    }
  }
`;

export const UpdateOrganizationHolidaysAndClosuresMutation = `
  mutation UpdateOrganizationHolidaysAndClosures(
    $id: Int!
    $organizationId: Int
    $closed: Boolean
    $date: String
    $startTime: String
    $endTime: String
  ) {
    updateOrganizationHolidaysAndClosures(input: {
      id: $id
      organizationId: $organizationId
      closed: $closed
      date: $date
      startTime: $startTime
      endTime: $endTime
    }) {
      id
    }
  }
`;

export const DeleteOrganizationHolidaysAndClosuresMutation = `
  mutation DeleteOrganizationHolidaysAndClosures(
    $id: Int
  ) {
    deleteOrganizationHolidaysAndClosures(input: {
      id: $id
    }) {
      id
    }
  }
`;

export const CreateOrganizationHolidaysAndClosuresMutation = `
  mutation createOrganizationHolidaysAndClosures(
    $organizationId: Int
    $closed: Boolean
    $date: String
    $startTime: String
    $endTime: String
  ) {
    createOrganizationHolidaysAndClosures(input: {
      organizationId: $organizationId
      date: $date
      startTime: $startTime
      endTime: $endTime
      closed: $closed
    }) {
      id
    }
  }
`;

export const UpdateLocationRegionMutation = `
  mutation UpdateLocationRegion(
    $id: Int,
    $regionId: Int,
  ) {
    updateLocationRegion(input: {
      id: $id,
      regionId: $regionId,
    }) {
      id
    }
  }
`;

export const GetOrganizationServiceProfilesQuery = `
  query GetOrganizationRelatedServiceProfiles(
    $organizationId: Int
  )  {
    getOrganizationRelatedServiceProfiles(input: {
      organizationId: $organizationId
    }) {
      id
      name
      ownerId
      ownerType
      owner
      updated_at
      updatedUser {
        id
        firstName
        lastName
      }
      locations {
        id
        name
      }
    }
  }
`;

export const UpdateFollowUpQuery = `
  mutation FollowUp(
    $id: Int,
    $notes: String
    $wasCallMade: Boolean
    $userId: Int
    $callResult: String
  ) {
    updateFollowUp(input: {
      id: $id,
      notes: $notes
      wasCallMade: $wasCallMade
      userId: $userId
      callResult: $callResult
    }) {
      user {
        id
        firstName
        lastName
      }
      updatedAt
    }
  }
`;

export const GetFollowUpsQuery = `
  query FollowUp($sfacId: Int, $districtId: Int, $regionId: Int, $organizationId: Int, $limit: Int, $offset: Int, $type: String, $status: String, $sort: String, $search: String) {
    followUps(input: { sfacId: $sfacId, districtId: $districtId, regionId: $regionId, organizationId: $organizationId, limit: $limit, offset: $offset, type: $type, status: $status, sort: $sort, search: $search}) {
      followUps {
        id
        title
        appointmentDate
        invoiceAmount
        invoiceDate
        invoiceNumber
        lifetimeSpend
        notes
        wasCallMade
        callResult
        created_at
        updated_at
        customerHistory
        otherVehicles
        updatedAt
        totalInvoices
        location {
          id
          name
          displayName
          displayLocation
          phone
        }
        user {
          id
          firstName
          lastName
        }
        customer {
          id
          firstName
          lastName
          phone
          email
          companyName
          customerInfos {
            id,
            locationId,
            invoicesTotal
          }
          customerEmails {
            id,
           email
          }
          customerPhones {
            id,
           phone
          }
          customerVehicles {
            id,
            year,
            make,
            model
          }
          customerBookings {
            id
            scheduledTime
            invoiceTotal
            sourceInvoiceNumber
            customerVehicleId
          }
        }
        vehicle {
          year
          make
          model
        }
        booking {
          id
          protractorServicesString
          services {
            name
            displayName
          }  
        }
      }
      count
    }
  }
`;

export const GetShopProperties = `
  query Location($id: Int) {
    location(input: {
      id: $id
    }) {
      id
      displayName
      displayLocation
      phone
      phoneText
      email
      openingDate
      shopHomeUrl
      staffBioUrl
      pricingUrl
      confirmationText
      active
      timezone
      videoFeeds {
       videoUrl
       id
      }
    }
  }
`;

export const UpdateShopProperties = `
  mutation updateShopProperties(
    $organizationId: Int,
    $id: Int,
    $displayName: String,
    $displayLocation: String,
    $phone: String,
    $phoneText: String,
    $confirmationText: String,
    $email: String,
    $active: Boolean,
    $openingDate: String,
    $shopHomeUrl: String,
    $staffBioUrl: String,
    $pricingUrl: String,
    $videoFeeds: [VideoFeedInput]
   ) {
    updateShopProperties(input: {
      organizationId: $organizationId,
      id: $id,
      displayName: $displayName,
      displayLocation: $displayLocation,
      phone: $phone,
      phoneText: $phoneText,
      confirmationText: $confirmationText,
      email: $email,
      active: $active,
      openingDate: $openingDate,
      shopHomeUrl: $shopHomeUrl,
      staffBioUrl: $staffBioUrl,
      pricingUrl: $pricingUrl,
      videoFeeds: $videoFeeds
    }) {
      id
    }
  }
`;

export const GetShopAddress = `
  query Location($id: Int) {
    location(input: {
      id: $id
    }) {
      id
       address {
        id
        street
        city
        state
        zipcode
      }
      timezone
      latitude
      longitude
    }
  }
`;

export const UpdateShopAddress = `
  mutation updateShopAddress(
    $id: Int,
    $address: AddressInput,
    $timezone: String,
    $latitude: String,
    $longitude: String,
   ) {
    updateShopAddress(input: {
      id: $id,
      address: $address,
      timezone: $timezone,
      latitude: $latitude,
      longitude: $longitude
    }) {
      id
    }
  }
`;

export const GetShopLaborRates = `
  query Location($id: Int) {
    location(input: {
      id: $id
    }) {
      id
      laborRates {
        id
        name
        displayName
        rate
        isRemovable
      }
    }
  }
`;

export const UpdateShopLaborRates = `
  mutation updateShopLaborRates(
    $id: Int,
    $laborRates: [LaborRateInput]
   ) {
    updateShopLaborRates(input: {
      id: $id,
      laborRates: $laborRates,
    }) {
      id
    }
  }
`;

export const GetShopEquipments = `
  query Location($id: Int) {
    location(input: {
      id: $id
    }) {
      id
      equipments {
        id
        displayName
        amount
      }
    }
  }
`;

export const UpdateShopEquipments = `
  mutation updateShopEquipments(
    $id: Int,
    $equipments: [EquipmentInput]
   ) {
    updateShopEquipments(input: {
      id: $id,
      equipments: $equipments,
    }) {
      id
    }
  }
`;

export const UpdateShopImages = `
  mutation updateShopImages(
    $id: Int,
    $locationImages: [LocationImageInput]
    $overrideCorporateLogo: Boolean
   ) {
    updateShopImages(input: {
      id: $id,
      locationImages: $locationImages,
      overrideCorporateLogo: $overrideCorporateLogo,
    }) {
      id
      locationImages {
        id
        name
        url
        type
      }
    }
  }
`;

export const GetShopAppointmentsUrl = `
  query Location($id: Int) {
    location(input: {
      id: $id
    }) {
      id
      shopAppointmentsUrl
    }
  }
`;

export const GetShopImages = `
  query Location($id: Int) {
    location(input: {
      id: $id
    }) {
      id
      overrideCorporateLogo
      locationImages {
        id
        name
        url
        type
      }
    }
  }
`;

export const UpdateShopVehicleBrands = `
  mutation updateShopVehicleBrands(
    $id: Int,
    $brands: [String]
   ) {
    updateShopVehicleBrands(input: {
      id: $id,
      brands: $brands,
    }) {
      id
    }
  }
`;

export const GetShopSocialMediaReviews = `
  query Location($id: Int) {
    location(input: {
      id: $id
    }) {
      id
      yelpReviewsBadge
      yelpReviewsLink
      yelpReviewsNumber
      googleReviewsBadge
      googleReviewsLink
      googleReviewsNumber
    }
  }
`;

export const UpdateShopSocialMediaReviews = `
  mutation updateShopSocialMedia(
    $id: Int,
    $yelpReviewsBadge: String
    $yelpReviewsLink: String
    $yelpReviewsNumber: Int
    $googleReviewsBadge: String
    $googleReviewsLink: String
    $googleReviewsNumber: Int
   ) {
    updateShopSocialMedia(input: {
      id: $id,
      yelpReviewsBadge: $yelpReviewsBadge,
      yelpReviewsLink: $yelpReviewsLink,
      yelpReviewsNumber: $yelpReviewsNumber,
      googleReviewsBadge: $googleReviewsBadge,
      googleReviewsLink: $googleReviewsLink,
      googleReviewsNumber: $googleReviewsNumber,
    }) {
      id
    }
  }
`;

export const GetDailyServiceAdvisorCalls = `
  query Location($id: Int) {
    location(input: {
      id: $id
    }) {
      id
      locationDailyServiceAdvisorCalls {
        id
        maxNumberOfCalls
        timePeriod
        timeUnit
      }
    }
  }
`;

export const UpdateShopDailyServiceAdvisorCalls = `
  mutation updateShopDailyServiceAdvisorCalls(
    $id: Int,
    $locationDailyServiceAdvisorCalls: [LocationDailyServiceAdvisorCallInput]
   ) {
    updateShopDailyServiceAdvisorCalls
    (input: {
      id: $id,
      locationDailyServiceAdvisorCalls: $locationDailyServiceAdvisorCalls,
    }) {
      id
    }
  }
`;

export const GetShopsAppointmentsUrl = `
query GetSFACLocations($showInactive: Boolean, $organizationId: Int, $regionId: Int, $districtId: Int) {
  getSFACLocations(input: { showInactive: $showInactive, organizationId: $organizationId, regionId: $regionId, districtId: $districtId }) {
    id
    name
    displayName
    displayLocation
    shopAppointmentsUrl
    locationIndex
  }
}
`;

export const GetMetabaseIframeURLQuery = `
  query GetMetabaseIframeURL($dashboardId: Int!) {
    getMetabaseIframeURL(input: {
      dashboardId: $dashboardId
    }) {
      iframeUrl
    }
  }
`;

export const ReSendBookingToProtractorMutation = `
  mutation reSendBookingToProtractor(
      $bookingId: Int!
      $sfacId: Int!
  ) {
    reSendBookingToProtractor(input: {
      bookingId: $bookingId
      sfacId: $sfacId
    }) {
      id
    }
  }
`;
