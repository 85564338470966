import { actorRanks, actorTypes } from '../../config/common';

const CHARACTERS_PER_LINE = 30;
const LINE_HEIGHT = 20;
const getRolesAndAccesses = user => {
  const uniqueAccessLevels = user.accessLevels.reduce((acc, current) => {
    if (!acc.some(item => item.id === current.id)) {
      acc.push(current);
    }
    return acc;
  }, []);

  return uniqueAccessLevels.map(accessLevel => {
    let accessData = '-';
    if (accessLevel.rank === actorRanks[actorTypes.OrganizationAdmin]) {
      accessData = user.adminOfOrganizations?.map(org => org.displayName).join(', ') || '-';
    } else if (accessLevel.rank === actorRanks[actorTypes.OrganizationOwner]) {
      accessData = user.ownerOfOrganizations?.map(org => org.displayName).join(', ') || '-';
    } else if (accessLevel.rank === actorRanks[actorTypes.RegionalGeneralManager]) {
      accessData = user.managerOfRegions?.map(region => region.name).join(', ') || '-';
    } else if (accessLevel.rank === actorRanks[actorTypes.DistrictManager]) {
      accessData = user.managerOfDistricts?.map(district => district.name).join(', ') || '-';
    } else if (accessLevel.rank === actorRanks[actorTypes.RegionalCustomerSupport]) {
      accessData = user.customerSupportOfRegions?.map(region => region.name).join(', ') || '-';
    } else if (accessLevel.rank === actorRanks[actorTypes.OrganizationCustomerSupport]) {
      accessData = user.customerSupportOfOrganizations?.map(org => org.displayName).join(', ') || '-';
    } else if (accessLevel.rank === actorRanks[actorTypes.LocationManager]) {
      accessData =
        user?.managerOfLocations
          ?.map(location => `${location.organization?.name?.toUpperCase()}${location.locationIndex}`)
          .join(', ') || '-';
    } else if (accessLevel.rank === actorRanks[actorTypes.AssistantGeneralManager]) {
      accessData =
        user?.assistantGeneralManagerOfLocations
          ?.map(location => `${location.organization?.name?.toUpperCase()}${location.locationIndex}`)
          .join(', ') || '-';
    } else if (accessLevel.rank === actorRanks[actorTypes.ServiceAdvisor]) {
      accessData =
        user?.serviceAdvisorOfLocations
          ?.map(location => `${location.organization?.name?.toUpperCase()}${location.locationIndex}`)
          .join(', ') || '-';
    } else if (accessLevel.rank === actorRanks[actorTypes.Technician]) {
      accessData =
        user?.technicianOfLocations
          ?.map(location => `${location.organization?.name?.toUpperCase()}${location.locationIndex}`)
          .join(', ') || '-';
    }

    const numRows = Math.ceil(Math.max(accessLevel.displayName.length, accessData.length) / CHARACTERS_PER_LINE);
    const itemHeight = numRows * LINE_HEIGHT + 30;

    return {
      role: accessLevel.displayName,
      access: accessData,
      height: itemHeight,
    };
  });
};

export default getRolesAndAccesses;
