import config from '@cardash/config';

export const getRouteByPath = (routes, path) => {
  return Object.values(routes).find(routeData => {
    const basePath = routeData.path;
    const pathWithoutParams = path.split('?')[0];
    return pathWithoutParams === basePath || pathWithoutParams.startsWith(`${basePath}/`);
  });
};

export const userHasRoutePermission = (route, userRole) => route.users.findIndex(role => role === userRole) !== -1;

export const routeBelongsToViewMode = (route, viewMode) =>
  route?.viewModes && route.viewModes.findIndex(mode => mode === (viewMode || config.viewModes.Master)) !== -1;

export const checkRouteAuthorization = (path, userRole, viewMode) => {
  const { routes } = config;
  if (!routes) return;
  const route = getRouteByPath(routes, path);
  if (!route) return;
  return !!route.users && userHasRoutePermission(route, userRole) && routeBelongsToViewMode(route, viewMode);
};
